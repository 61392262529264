<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest"
import Preference from "@/data/Preference";
import Constants from "@/data/Constants"
import {url, required} from "vuelidate/lib/validators";

let httpRequest = new HttpRequest()
let preference = new Preference()

export default {

  data() {
    return {
      title: "Integrations",
      isAdmin: preference.getData(Preference.KEY_ROLE_ID) == Constants.ROLE_OWNER,
      items: [
        {
          text: "WORKANY",
          to:'/'
        },
        {
          text: "Integration",
          active: true
        }
      ],
      isLoading: false,
      submitted: false,
      isJiraSyncing: false,
      jiraIsLoading: false,
      isJiraIntegrated: false,
      jiraHostUrl: null,
      consumer_key: null,
      api_version: null,
      private_key: null,
      oauth_verifier: null,
      oauthToken: null,
      oauthTokenSecret: null
    }
  },
  validations: {
    jiraHostUrl: {required, url},
    consumer_key: {required},
    private_key: {required},
    api_version: {required},
  },
  computed: {
    jiraSynDisabled() {
      return !this.isJiraIntegrated;
    },
  },
  components: {
    Layout,
    PageHeader
  },

  methods: {
    addJira() {
      let _this = this
      _this.jiraIsLoading = true
      return httpRequest.initJira().then(function (response) {
        _this.jiraIsLoading = false
        if (response.success) {
          _this.$bvModal.hide('AddJiraModal')
          _this.oauthToken = response.data.oauthToken
          _this.oauthTokenSecret = response.data.oauthTokenSecret
          _this.oauthTokenSecret = response.data.oauthTokenSecret
          preference.setData(Preference.KEY_JIRA_ACCESS_TOKEN, _this.oauthToken)
          preference.setData(Preference.KEY_JIRA_ACCESS_TOKEN_SECRET, _this.oauthTokenSecret)
          preference.setData(Preference.KEY_JIRA_HOST_URL, _this.jiraHostUrl)
          //location.replace(response.data.url)
          window.open(response.data.url, '_blank');
        } else {
          _this.$bvModal.hide('AddJiraModal')
          _this.$swal({
            text: 'Jira connection error!',
          });
        }
      })
    },
    addJiraConfig() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let _this = this
        return httpRequest.addJiraConfig(_this.jiraHostUrl, _this.consumer_key, _this.private_key, _this.api_version).then(function (response) {
          _this.jiraIsLoading = false
          if (response.success) {
            _this.$bvModal.hide('AddJiraModal')
            _this.$swal({
              text: 'Jira connection success!',
            });
            _this.loadInitialJiraConfig()
          } else {
            _this.$bvModal.hide('AddJiraModal')
            _this.$swal({
              text: 'Jira connection error!',
            });
          }
        })
      }
    },
    loadInitialData() {
      let _this = this
      _this.isLoading = true
      let userId = preference.getData(Preference.KEY_USER_ID)
      return httpRequest.getIntegrationVendors(userId).then(function (response) {
        _this.isLoading = false
        if (response.success) {
          _this.isJiraIntegrated = true
        }
      })
    },

    loadInitialJiraConfig() {
      let _this = this
      _this.isLoading = true
      return httpRequest.getJiraConfig().then(function (response) {
        _this.isLoading = false
        if (response.success) {
          _this.isJiraIntegrated = true
          _this.jiraHostUrl = response.data.jira_url
          _this.consumer_key = response.data.consumer_key
          _this.private_key = response.data.private_key
          _this.api_version = response.data.jira_api_version
        }
      })
    },
    syncJira() {
      let _this = this
      _this.isJiraSyncing = true
      let userId = preference.getData(Preference.KEY_USER_ID)
      let jiraHostUrl = preference.getData(Preference.KEY_JIRA_HOST_URL)
      return httpRequest.synJira(userId, jiraHostUrl , _this.api_version).then(function (response) {
        _this.isJiraSyncing = false
        if (response.success) {
          _this.$swal({
            text: 'Jira Syncing Successful',
          });
        } else {
          _this.$swal({
            text: response.message,
          });
        }
      })

    },
  }
  ,
  async created() {
    await this.loadInitialData()
    if (this.isAdmin) {
      await this.loadInitialJiraConfig()
    }
  }

}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row report-form-row">
              <div class="col-md-4 col-xl-2 report-search">
                <b-button
                    style="height: 80px"
                    block
                    v-if="isAdmin"
                    size="lg"
                    class="customs-bg-color mb-5"
                    variant="primary"
                    v-b-modal.AddJiraModal
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Integrate Jira"
                ><i class="ri-add-line" style="vertical-align:bottom"></i> ADD JIRA
                </b-button>
                <b-button
                    block
                    variant="primary"
                    class="customs-bg-color"
                    v-on:click="addJira()"
                    v-show="isJiraIntegrated"
                >
                  <b-spinner style="width: 1.5rem; height: 1.5rem;" label="Text Centered"
                             v-if="jiraIsLoading"></b-spinner>
                  <i class="ri-links-line" style="vertical-align:bottom"></i> CONNECT JIRA
                </b-button>
                <b-button
                    block
                    variant="primary"
                    class="customs-bg-color"
                    v-on:click="syncJira()"
                    v-show="!jiraSynDisabled"
                >
                  <b-spinner style="width: 1.5rem; height: 1.5rem;" label="Text Centered"
                             v-if="isJiraSyncing"></b-spinner>
                  <i class="ri-refresh-line" style="vertical-align:bottom"></i> SYNC
                </b-button>

              </div>
            </div>
            <div class="row report-form-row mt-2">
              <div class=" font-weight-bold font-size-16 ml-2" v-show="!isAdmin && !isJiraIntegrated" style="color: #ff7c47">
                JIRA isn't configured yet, Please contact with your Admin.
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-3 mb-3" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>

    <!--task List modals-->
    <div class="align-items-center text-center text-md-left">
      <b-modal id="AddJiraModal" centered title="JIRA Configuration" hide-footer>
        <form class="form-horizontal" @submit.prevent="">

          <div class="form-group mb-4">
            <label for="jira_host_url">Jira Host Url</label>
            <input
                type="text"
                class="form-control"
                id="jira_host_url"
                :class="{ 'is-invalid': submitted && $v.jiraHostUrl.$error }"
                placeholder="Enter Jira Host Url"
                v-model=jiraHostUrl
            />
            <div v-if="submitted && $v.jiraHostUrl.$error" class="invalid-feedback">
              <span v-if="!$v.jiraHostUrl.required">Jira Host URL is required.</span>
              <span v-if="!$v.jiraHostUrl.url">Please enter valid URL.</span>
            </div>
          </div>
          <div class="form-group mb-4">
            <label for="consumer_key">Consumer Key</label>
            <input
                type="text"
                class="form-control"
                id="consumer_key"
                :class="{ 'is-invalid': submitted && $v.consumer_key.$error }"
                placeholder="Enter Consumer Key"
                v-model=consumer_key
            />
            <div
                v-if="submitted && !$v.consumer_key.required"
                class="invalid-feedback">Consumer key is required.
            </div>
          </div>
<!--           jira api version input field  -->
          <div class="form-group mb-4">
            <label for="api_version">JIRA API Version</label>
            <input
                type="number"
                class="form-control"
                id="api_version"
                :class="{ 'is-invalid': submitted && $v.api_version.$error }"
                placeholder="Enter Jira Api Version"
                v-model=api_version
            />
            <div
                v-if="submitted && !$v.api_version.required"
                class="invalid-feedback">API version is required.
            </div>
          </div>

          <div class="form-group  mb-4">
            <label for="private_key">Private Key</label>
            <textarea
                class="form-control"
                id="private_key"
                rows="7"
                :class="{ 'is-invalid': submitted && $v.private_key.$error }"
                placeholder="Enter Private Key"
                v-model=private_key
            />
            <div
                v-if="submitted && !$v.private_key.required"
                class="invalid-feedback">Private key is required.
            </div>
          </div>


          <div class="mt-4 text-center">
            <b-button
                variant="primary"
                size="lg"
                class="customs-bg-color"
                type="submit"
                v-on:click="addJiraConfig"
            >SAVE
            </b-button>
          </div>
          <div class="mt-4 text-center" v-if="jiraIsLoading">
            <div class="text-center mt-3 mb-3">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>

<style>

.customs-bg-color {
  background-color: #ff7c47 !important;
  border-color: #ff7c47;
}

.btn-outline-primary:hover {
  border-color: #bdc5f1 !important;
}

</style>
